import { Dialog, Transition } from '@headlessui/react';
import { DateTime } from 'luxon';
import { thousandSeparator } from 'ody-utils';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';

interface WinPlayersProps {
  open: boolean;
  set_open: React.Dispatch<React.SetStateAction<boolean>>;
  pasar: string;
  keluaran: [string, string, string];
  website: string;
  game_type: string;
}

const WinPlayers: React.FC<WinPlayersProps> = props => {
  const { open, set_open, pasar, keluaran, website, game_type } = props;

  const [win_players, set_win_players] = useState<
    {
      bet_number: string;
      bet_value: number;
      player_id: string;
      transaction_time: string;
      win_amount: number;
    }[]
  >([]);

  const { promiseInProgress: getting_win_players } = usePromiseTracker();

  const get_win_players = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/bet/win-players?pasar=${pasar}&keluaran_1=${keluaran[0]}&keluaran_2=${keluaran[1]}&keluaran_3=${keluaran[2]}&website=${website}&game_type=${game_type}`
      );

      const res = await response.json();
      if (!response.ok) throw new Error(res.message);
      set_win_players(res.data.win_players);
    } catch (error: any) {
      alert(error.message);
    }
  }, [pasar, keluaran, game_type, website]);

  useEffect(() => {
    if (!open) return;
    trackPromise(get_win_players());
  }, [open, get_win_players]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={set_open}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-3xl sm:p-6'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0 text-center'
                      >
                        player id
                      </th>

                      <th
                        scope='col'
                        className='px-3 py-3.5 text-sm font-semibold text-gray-900 text-center'
                      >
                        transaction time
                      </th>

                      <th
                        scope='col'
                        className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'
                      >
                        bet number
                      </th>

                      <th
                        scope='col'
                        className='px-3 py-3.5 text-right text-sm font-semibold text-gray-900'
                      >
                        bet value
                      </th>

                      <th
                        scope='col'
                        className='px-3 py-3.5 text-right text-sm font-semibold text-gray-900'
                      >
                        player win
                      </th>
                    </tr>
                  </thead>

                  <tbody className='divide-y divide-gray-200'>
                    {getting_win_players ? (
                      <>
                        <tr>
                          <td
                            colSpan={99}
                            className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-center'
                          >
                            <LoadingIndicator colorScheme='dark' />
                          </td>
                        </tr>
                      </>
                    ) : (
                      win_players.map(win_player => (
                        <tr
                          key={
                            win_player.player_id +
                            win_player.bet_number +
                            win_player.bet_value
                          }
                        >
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-center'>
                            {win_player.player_id}
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-mono font-light text-center'>
                            {DateTime.fromISO(
                              win_player.transaction_time
                            ).toFormat('dd LLL yyyy - HH:mm:ss')}
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-mono font-light text-center'>
                            {win_player.bet_number}
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-mono font-light text-right'>
                            {thousandSeparator({
                              numbers: Math.round(win_player.bet_value),
                            })}
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-mono font-light text-right'>
                            {thousandSeparator({
                              numbers: Math.round(win_player.win_amount),
                            })}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                <div className='mt-5'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md bg-chestnut-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-chestnut-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chestnut-rose-600'
                    onClick={() => set_open(false)}
                  >
                    kembali
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WinPlayers;
