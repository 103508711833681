import { Switch } from '@headlessui/react';
import { useContext } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { pasaran_list } from '../../../../constants/pasaran/';
import { PrediksiContext } from '../../../../context/prediksi';
import { class_names } from '../../../../utils/class-names';
import { get_bet_list, get_results } from '../prediksi';

const Filter = () => {
  const {
    pasaran,
    set_pasaran,
    set_bet_list,
    set_results,
    show_twin,
    set_show_twin,
    shown_numbers,
    set_shown_numbers,
    as,
    set_as,
    kop,
    set_kop,
  } = useContext(PrediksiContext);

  const { promiseInProgress: loading_tarik } = usePromiseTracker();

  const tarik = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/bet/fetch`,
        {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ pasaran: pasaran.toUpperCase() }),
        }
      );

      const res = await response.json();
      if (!response.ok) throw new Error(res.message);

      const bet_list = await get_bet_list({
        signal: new AbortController().signal,
        pasaran,
      });

      set_bet_list(bet_list);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const clear_login_data = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/etc/clear-login-data`,
      { credentials: 'include', method: 'POST' }
    );

    const res = await response.json();
    alert(res.message);
  };

  const clear_results = async () => {
    if (!window.confirm('yakin mau menghapus semua result?')) return;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/result/clear?pasaran=${pasaran}`,
      { credentials: 'include', method: 'DELETE' }
    );

    const res = await response.json();
    alert(res.message);

    const results = await get_results({
      signal: new AbortController().signal,
      pasaran,
    });

    set_results(results);
  };

  return (
    <>
      <div className='flex justify-between w-full items-stretch'>
        <div className='flex space-x-2'>
          <select
            className='block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-chestnut-rose-600 sm:text-sm sm:leading-6'
            defaultValue={pasaran}
            onChange={e => set_pasaran(e.target.value)}
          >
            {pasaran_list.map(pasaran => (
              <option key={pasaran}>{pasaran}</option>
            ))}
          </select>

          <button
            type='button'
            className='rounded-md bg-chestnut-rose-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-chestnut-rose-500 active:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chestnut-rose-600 flex items-center justify-center'
            onClick={() => {
              trackPromise(tarik());
            }}
          >
            {loading_tarik ? <LoadingIndicator colorScheme='light' /> : 'tarik'}
          </button>

          <Switch.Group
            as='div'
            className='flex items-center ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-chestnut-rose-600 rounded-md px-3'
          >
            <Switch
              checked={show_twin}
              onChange={set_show_twin}
              className={class_names(
                show_twin ? 'bg-chestnut-rose-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
              )}
            >
              <span
                className={class_names(
                  show_twin ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              >
                <span
                  className={class_names(
                    show_twin
                      ? 'opacity-0 duration-100 ease-out'
                      : 'opacity-100 duration-200 ease-in',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden='true'
                >
                  <svg
                    className='h-3 w-3 text-gray-400'
                    fill='none'
                    viewBox='0 0 12 12'
                  >
                    <path
                      d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                      stroke='currentColor'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>

                <span
                  className={class_names(
                    show_twin
                      ? 'opacity-100 duration-200 ease-in'
                      : 'opacity-0 duration-100 ease-out',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden='true'
                >
                  <svg
                    className='h-3 w-3 text-chestnut-rose-600'
                    fill='currentColor'
                    viewBox='0 0 12 12'
                  >
                    <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                  </svg>
                </span>
              </span>
            </Switch>

            <Switch.Label as='span' className='ml-3 text-sm'>
              <span className='font-medium text-gray-900 pr-1'>kembar</span>
            </Switch.Label>
          </Switch.Group>

          <div className='flex text-sm font-medium'>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
              <div
                key={`filter-num-${num}`}
                className={`flex items-center justify-center px-2 border border-r-0 last-of-type:border-r border-chestnut-rose-300 first-of-type:rounded-tl-md first-of-type:rounded-bl-md last-of-type:rounded-tr-md last-of-type:rounded-br-md w-10 cursor-pointer transition-colors ${
                  shown_numbers.includes(num)
                    ? 'bg-chestnut-rose-600 text-white'
                    : 'bg-white text-gray-900'
                }`}
                onClick={() => {
                  if (shown_numbers.includes(num)) {
                    set_shown_numbers(
                      shown_numbers.filter(number => number !== num)
                    );
                  } else {
                    set_shown_numbers([...shown_numbers, num]);
                  }
                }}
              >
                {num}
              </div>
            ))}
          </div>

          <div
            className={class_names(
              'flex items-center ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-chestnut-rose-600 rounded-md px-3 text-sm font-medium',
              as !== '' ? 'bg-chestnut-rose-600 text-white' : ''
            )}
          >
            as{' '}
            <input
              type='text'
              className={class_names(
                'w-10 border-0 text-center border-transparent focus:border-transparent focus:ring-0 text-sm font-bold text-chestnut-rose-700',
                as !== '' ? 'bg-chestnut-rose-600 text-white' : ''
              )}
              placeholder='...'
              onChange={e => {
                set_as(e.target.value.substring(e.target.value.length - 1));
              }}
              value={as}
              onKeyDown={e => {
                const allowed_keys = [
                  'Backspace',
                  'Delete',
                  'ArrowLeft',
                  'ArrowRight',
                  'Tab',
                ];

                if (
                  (isNaN(Number(e.key)) && !allowed_keys.includes(e.key)) ||
                  e.key === ' '
                ) {
                  e.preventDefault();
                  return;
                }

                if (!allowed_keys.includes(e.key))
                  set_as(e.key.substring(e.key.length - 1));

                if (e.key === 'Backspace') set_as('');
              }}
            />
          </div>

          <div
            className={class_names(
              'flex items-center ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-chestnut-rose-600 rounded-md px-3 text-sm font-medium',
              kop !== '' ? 'bg-chestnut-rose-600 text-white' : ''
            )}
          >
            kop{' '}
            <input
              type='text'
              className={class_names(
                'w-10 border-0 text-center border-transparent focus:border-transparent focus:ring-0 text-sm font-bold text-chestnut-rose-700',
                kop !== '' ? 'bg-chestnut-rose-600 text-white' : ''
              )}
              placeholder='...'
              onChange={e => {
                set_kop(e.target.value.substring(e.target.value.length - 1));
              }}
              value={kop}
              onKeyDown={e => {
                const allowed_keys = [
                  'Backspace',
                  'Delete',
                  'ArrowLeft',
                  'ArrowRight',
                  'Tab',
                ];

                if (
                  (isNaN(Number(e.key)) && !allowed_keys.includes(e.key)) ||
                  e.key === ' '
                ) {
                  e.preventDefault();
                  return;
                }

                if (!allowed_keys.includes(e.key))
                  set_kop(e.key.substring(e.key.length - 1));

                if (e.key === 'Backspace') set_kop('');
              }}
            />
          </div>

          {/* <Switch.Group
            as='div'
            className='flex items-center ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-chestnut-rose-600 rounded-md px-3'
          >
            <Switch
              checked={maxed_out}
              onChange={set_maxed_out}
              className={class_names(
                maxed_out ? 'bg-chestnut-rose-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
              )}
            >
              <span
                className={class_names(
                  maxed_out ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              >
                <span
                  className={class_names(
                    maxed_out
                      ? 'opacity-0 duration-100 ease-out'
                      : 'opacity-100 duration-200 ease-in',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden='true'
                >
                  <svg
                    className='h-3 w-3 text-gray-400'
                    fill='none'
                    viewBox='0 0 12 12'
                  >
                    <path
                      d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                      stroke='currentColor'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>

                <span
                  className={class_names(
                    maxed_out
                      ? 'opacity-100 duration-200 ease-in'
                      : 'opacity-0 duration-100 ease-out',
                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                  )}
                  aria-hidden='true'
                >
                  <svg
                    className='h-3 w-3 text-chestnut-rose-600'
                    fill='currentColor'
                    viewBox='0 0 12 12'
                  >
                    <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                  </svg>
                </span>
              </span>
            </Switch>

            <Switch.Label as='span' className='ml-3 text-sm'>
              <span className='font-medium text-gray-900 pr-1'>
                max&nbsp;profit
              </span>
            </Switch.Label>
          </Switch.Group> */}
        </div>

        <div className='h-full flex space-x-2'>
          <button
            type='button'
            className='rounded-md bg-chestnut-rose-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-chestnut-rose-500 active:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chestnut-rose-600 flex items-center justify-center'
            onClick={clear_results}
          >
            clear results
          </button>

          <button
            type='button'
            className='rounded-md bg-chestnut-rose-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-chestnut-rose-500 active:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chestnut-rose-600 flex items-center justify-center'
            onClick={clear_login_data}
          >
            clear login data
          </button>
        </div>
      </div>
    </>
  );
};

export default Filter;
