import { createContext } from 'react';
import { pasaran_list } from '../constants/pasaran';
import { BetListType } from '../types/bet-list';
import { Result } from '../types/result';

type PrediksiContextType = {
  pasaran: string;
  set_pasaran: React.Dispatch<React.SetStateAction<string>>;
  bet_list: BetListType[];
  set_bet_list: React.Dispatch<React.SetStateAction<BetListType[]>>;
  get_bet_list: ({
    signal,
    pasaran,
  }: {
    signal: AbortSignal;
    pasaran: string;
  }) => Promise<BetListType[]>;
  results: Result[];
  set_results: React.Dispatch<React.SetStateAction<Result[]>>;
  show_twin: boolean;
  set_show_twin: React.Dispatch<React.SetStateAction<boolean>>;
  shown_numbers: number[];
  set_shown_numbers: React.Dispatch<React.SetStateAction<number[]>>;
  as: string;
  set_as: React.Dispatch<React.SetStateAction<string>>;
  kop: string;
  set_kop: React.Dispatch<React.SetStateAction<string>>;
};

const PrediksiContext = createContext<PrediksiContextType>({
  pasaran: pasaran_list[0],
  set_pasaran: () => {},
  bet_list: [],
  set_bet_list: () => {},
  get_bet_list: async () => [],
  results: [],
  set_results: () => {},
  show_twin: true,
  set_show_twin: () => {},
  shown_numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  set_shown_numbers: () => {},
  as: '',
  set_as: () => {},
  kop: '',
  set_kop: () => {},
});

export { PrediksiContext };
