import { Disclosure } from '@headlessui/react';
import { NavLink, Outlet } from 'react-router-dom';

const Dashboard = () => {
  return (
    <>
      <div>
        <Disclosure as='nav' className='bg-white shadow'>
          <div className='mx-auto max-w-8xl px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 justify-between'>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='flex flex-shrink-0 items-center bg-black'>
                  <img
                    className='h-full w-auto'
                    src={require('../assets/images/logo.jpg')}
                    alt='Hasil Hoki'
                  />
                </div>

                <div className='hidden sm:ml-6 sm:flex sm:space-x-8'>
                  <NavLink
                    to='prediksi'
                    className={({ isActive }) =>
                      `inline-flex items-center border-chestnut-rose-500 px-1 pt-1 text-sm font-medium text-gray-900 ${
                        isActive ? 'border-b-2' : ''
                      }`
                    }
                  >
                    prediksi
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 pb-4 pt-2'>
              <Disclosure.Button
                as='a'
                href='#'
                className='block border-l-4 border-chestnut-rose-500 bg-chestnut-rose-50 py-2 pl-3 pr-4 text-base font-medium text-chestnut-rose-700'
              >
                Dashboard
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </Disclosure>

        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
