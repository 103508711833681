import {
  MagnifyingGlassIcon,
  RocketLaunchIcon,
} from '@heroicons/react/20/solid';
import { thousandSeparator } from 'ody-utils';
import { Fragment, useContext, useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import ToasterMessage from '../../../../components/ToasterMessage';
import { PrediksiContext } from '../../../../context/prediksi';
import { BetListType } from '../../../../types/bet-list';
import { add_leading_zero } from '../../../../utils/add-leading-zero';
import { is_twin } from '../../../../utils/is-twin';
import { get_results } from '../prediksi';
import Filter from './filter';
import WinPlayers from './win-players';

const promise_area = {
  generate: 'generate',
};

const BetTable = () => {
  const [showing_detail, set_showing_detail] = useState<number[]>([]);
  const [showing_win_detail, set_showing_win_detail] = useState<string[]>([]);
  const [show_win_players_modal, set_show_win_players_modal] = useState(false);
  const [angka_keluar_to_be_checked, set_angka_keluar_to_be_checked] = useState<
    string[]
  >([]);
  const [website_to_be_checked, set_website_to_be_checked] = useState('');
  const [game_type_to_be_checked, set_game_type_to_be_checked] =
    useState<string>('');

  const {
    bet_list,
    pasaran,
    results,
    set_results,
    show_twin,
    shown_numbers,
    as,
    kop,
  } = useContext(PrediksiContext);

  const { promiseInProgress: generating } = usePromiseTracker({
    area: promise_area.generate,
  });

  useEffect(() => {
    const abort_controller = new AbortController();

    (async () => {
      const results = await get_results({
        signal: abort_controller.signal,
        pasaran,
      });

      set_results(results);
    })();

    return () => {
      abort_controller.abort();
    };
  }, [pasaran, set_results]);

  const generate = async ({ bet_number }: { bet_number: string }) => {
    try {
      const response = await fetch('/api/v1/result/generate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pasaran, bet_number, as, kop }),
      });

      const res = await response.json();
      if (!response.ok) throw new Error(res.message);

      const results = await get_results({
        signal: new AbortController().signal,
        pasaran,
      });

      set_results(results);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const [display_toaster, set_display_toaster] = useState(false);

  const display_info = () => {
    set_display_toaster(true);

    setTimeout(() => {
      set_display_toaster(false);
    }, 1000);
  };

  return (
    <>
      <div className='sm:flex sm:items-center'>
        <Filter />
      </div>

      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0'
                  >
                    nomor
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900'
                  >
                    jumlah bet
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900'
                  >
                    nominal bet
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900 text-right'
                  >
                    2d player win
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900'
                  >
                    keluaran 1
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900'
                  >
                    keluaran 2
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900'
                  >
                    keluaran 3
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900 text-right'
                  >
                    profit
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900 text-right'
                  >
                    total omset
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-sm font-semibold text-gray-900'
                  >
                    margin
                  </th>

                  <th
                    scope='col'
                    className='relative py-3.5 pl-3 pr-4 sm:pr-0'
                  ></th>
                </tr>
              </thead>

              <tbody className='divide-y divide-gray-200'>
                {Array.from({ length: 100 }, (_, i) => i)
                  .map(num => {
                    const bet = bet_list.find(bet => bet._id === num);
                    return (
                      bet ||
                      ({
                        _id: num,
                        bet_count: 0,
                        total_bet_value: 0,
                        total_player_win: 0,
                      } as BetListType)
                    );
                  })
                  .sort((a, b) => a.total_player_win - b.total_player_win)
                  .map(bet => {
                    const result = results?.find(result => {
                      return (
                        result.angka_keluar[0].substring(
                          result.angka_keluar[0].length - 2
                        ) === add_leading_zero(bet._id.toString())
                      );
                    });

                    const hasil = result?.hasil || 0;
                    const total_omset = result?.total_omset || 0;

                    if (
                      (!show_twin &&
                        is_twin(add_leading_zero(bet._id.toString()))) ||
                      add_leading_zero(bet._id.toString())
                        .split('')
                        .some(num => !shown_numbers.includes(Number(num)))
                    )
                      return <Fragment key={bet._id}></Fragment>;

                    return (
                      <Fragment key={bet._id}>
                        <tr
                          onClick={() => {
                            if (showing_detail.includes(bet._id)) {
                              set_showing_detail(showing_detail => {
                                showing_detail = showing_detail.filter(
                                  id => id !== bet._id
                                );

                                return [...showing_detail];
                              });
                            } else {
                              set_showing_detail(showing_detail => {
                                showing_detail.push(bet._id);
                                return [...showing_detail];
                              });
                            }
                          }}
                          className='hover:bg-gray-50 cursor-pointer'
                        >
                          <td className='whitespace-nowrap text-center py-4 font-mono pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                            {add_leading_zero(bet?._id.toString())}
                          </td>

                          <td className='whitespace-nowrap text-center px-3 py-4 font-mono text-sm text-gray-500'>
                            {thousandSeparator({
                              numbers: bet?.bet_count || 0,
                            })}
                          </td>

                          <td className='whitespace-nowrap text-center px-3 py-4 font-mono text-sm text-gray-500'>
                            {thousandSeparator({
                              numbers: bet?.total_bet_value || 0,
                            })}
                          </td>

                          <td className='whitespace-nowrap text-right px-3 py-4 font-mono text-sm text-gray-500'>
                            {thousandSeparator({
                              numbers: bet?.total_player_win || 0,
                            })}
                          </td>

                          <td className='whitespace-nowrap text-center px-3 py-4 font-mono text-sm text-gray-500'>
                            <span
                              onClick={async e => {
                                e.stopPropagation();

                                await navigator.clipboard.writeText(
                                  result?.angka_keluar[0] || ''
                                );

                                display_info();
                              }}
                            >
                              {result?.angka_keluar[0]}
                            </span>
                          </td>

                          <td className='whitespace-nowrap text-center px-3 py-4 font-mono text-sm text-gray-500'>
                            <span
                              onClick={async e => {
                                e.stopPropagation();

                                await navigator.clipboard.writeText(
                                  result?.angka_keluar[1] || ''
                                );

                                display_info();
                              }}
                            >
                              {result?.angka_keluar[1]}
                            </span>
                          </td>

                          <td className='whitespace-nowrap text-center px-3 py-4 font-mono text-sm text-gray-500'>
                            <span
                              onClick={async e => {
                                e.stopPropagation();

                                await navigator.clipboard.writeText(
                                  result?.angka_keluar[2] || ''
                                );

                                display_info();
                              }}
                            >
                              {result?.angka_keluar[2]}
                            </span>
                          </td>

                          <td className='whitespace-nowrap text-right px-3 py-4 font-mono text-sm text-gray-500'>
                            {thousandSeparator({ numbers: hasil })}
                          </td>

                          <td className='whitespace-nowrap text-right px-3 py-4 font-mono text-sm text-gray-500'>
                            {thousandSeparator({ numbers: total_omset })}
                          </td>

                          <td className='whitespace-nowrap text-center px-3 py-4 font-mono text-sm text-gray-500'>
                            {Math.round((hasil / total_omset) * 100) || 0}%
                          </td>

                          <td className='relative whitespace-nowrap text-center py-4 font-mono pl-3 pr-4 text-sm font-medium sm:pr-0'>
                            <button
                              type='button'
                              className='rounded-full bg-chestnut-rose-600 p-2 text-white shadow-sm hover:bg-chestnut-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chestnut-rose-600 active:opacity-90 flex items-center justify-center'
                              onClick={e => {
                                e.stopPropagation();

                                trackPromise(
                                  generate({ bet_number: bet._id.toString() }),
                                  promise_area.generate
                                );
                              }}
                            >
                              {generating ? (
                                <LoadingIndicator colorScheme='light' />
                              ) : (
                                <RocketLaunchIcon className='h-5 w-5' />
                              )}
                            </button>
                          </td>
                        </tr>

                        {Object.entries(result?.detail_set || {})
                          .sort(
                            ([_, a_detail], [__, b_detail]) =>
                              Number(a_detail.profit) /
                                Number(a_detail['Total Omset']) -
                              Number(b_detail.profit) /
                                Number(b_detail['Total Omset'])
                          )
                          .map(([website, detail]) => {
                            const profit = Math.round(Number(detail.profit));

                            const omset = Math.round(
                              Number(detail['Total Omset'])
                            );

                            return (
                              <Fragment key={bet + website}>
                                <tr
                                  className={`${
                                    showing_detail.includes(bet._id)
                                      ? 'bg-chestnut-rose-50 text-gray-500 text-sm hover:bg-chestnut-rose-100 cursor-pointer'
                                      : 'hidden'
                                  }`}
                                  onClick={() => {
                                    if (
                                      showing_win_detail.includes(
                                        bet._id.toString() + website
                                      )
                                    ) {
                                      set_showing_win_detail(
                                        showing_win_detail => {
                                          showing_win_detail =
                                            showing_win_detail.filter(
                                              id =>
                                                id !==
                                                bet._id.toString() + website
                                            );

                                          return [...showing_win_detail];
                                        }
                                      );
                                    } else {
                                      set_showing_win_detail(
                                        showing_win_detail => {
                                          showing_win_detail.push(
                                            bet._id.toString() + website
                                          );

                                          return [...showing_win_detail];
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <td className='whitespace-nowrap text-center px-3 py-4 text-chestnut-rose-950 font-medium'>
                                    {website}
                                  </td>

                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                  <td className='whitespace-nowrap text-right px-3 py-4 font-mono font-light'>
                                    {thousandSeparator({ numbers: profit })}
                                  </td>

                                  <td className='whitespace-nowrap text-right px-3 py-4 font-mono font-light'>
                                    {thousandSeparator({ numbers: omset })}
                                  </td>

                                  <td className='whitespace-nowrap text-center px-3 py-4 font-mono font-light'>
                                    {Math.round((profit / omset) * 100) || 0}%
                                  </td>

                                  <td className='relative whitespace-nowrap text-center py-4 font-mono pl-3 pr-4 text-sm font-medium sm:pr-0'></td>
                                </tr>

                                {Object.entries(detail).map(
                                  ([game, player_win]) => {
                                    return (
                                      <tr
                                        className={`${
                                          showing_detail.includes(bet._id) &&
                                          showing_win_detail.includes(
                                            bet._id.toString() + website
                                          ) &&
                                          Number(player_win) &&
                                          game !== 'Total Omset' &&
                                          game !== 'profit'
                                            ? 'bg-chestnut-rose-100 text-gray-500 text-sm'
                                            : 'hidden'
                                        }`}
                                        key={
                                          bet._id.toString() + website + game
                                        }
                                      >
                                        <td className='whitespace-nowrap px-3 py-4 text-center'>
                                          {game}
                                        </td>

                                        <td></td>
                                        <td></td>

                                        <td className='whitespace-nowrap text-right px-3 py-4 font-mono font-light'>
                                          {thousandSeparator({
                                            numbers: Math.round(
                                              Math.round(Number(player_win))
                                            ),
                                          })}
                                        </td>

                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td className='relative whitespace-nowrap text-center py-4 font-mono pl-3 pr-4 text-sm font-medium sm:pr-0'>
                                          <button
                                            type='button'
                                            className='rounded-full bg-chestnut-rose-600 p-1.5 text-white shadow-sm hover:bg-chestnut-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chestnut-rose-600 active:opacity-90 flex items-center justify-center'
                                            onClick={() => {
                                              set_show_win_players_modal(true);
                                              set_angka_keluar_to_be_checked(
                                                result?.angka_keluar || []
                                              );
                                              set_website_to_be_checked(
                                                website
                                              );
                                              set_game_type_to_be_checked(game);
                                            }}
                                          >
                                            <MagnifyingGlassIcon className='h-4 w-4' />
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </Fragment>
                            );
                          })}
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ToasterMessage display_toaster={display_toaster} message='copied!' />

      <WinPlayers
        open={show_win_players_modal}
        set_open={set_show_win_players_modal}
        pasar={pasaran}
        keluaran={[
          angka_keluar_to_be_checked[0] || '',
          angka_keluar_to_be_checked[1] || '',
          angka_keluar_to_be_checked[2] || '',
        ]}
        website={website_to_be_checked}
        game_type={game_type_to_be_checked}
      />
    </>
  );
};

export default BetTable;
