import { pasaran_2ad } from './2ad';
import { pasaran_hasil_hoki } from './hasil-hoki';

const pasaran_list: string[] = [];

switch (process.env.REACT_APP_GROUP) {
  case 'hasil-hoki':
    pasaran_list.push(...pasaran_hasil_hoki);
    break;

  case '2ad':
    pasaran_list.push(...pasaran_2ad);
    break;

  default:
    throw new Error('process.env.REACT_APP_GROUP invalid');
}

export { pasaran_list };
