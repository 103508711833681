import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';

interface ToasterMessageProps {
  display_toaster: boolean;
  message: string;
}

const ToasterMessage: React.FC<ToasterMessageProps> = props => {
  const { display_toaster, message } = props;

  return (
    <Transition.Root
      show={display_toaster}
      className='fixed left-0 right-0'
      enter='transition-all duration-150'
      enterFrom='opacity-0 top-0'
      enterTo='opacity-100 top-8'
      leave='transition-all duration-150'
      leaveFrom='opacity-100 top-8'
      leaveTo='opacity-0 top-0'
    >
      <div className='rounded-md bg-chestnut-rose-50 p-4 shadow-md mx-auto w-fit'>
        <div className='flex'>
          <div className='flex-shrink-0'>
            <CheckCircleIcon className='h-5 w-5 text-chestnut-rose-400' />
          </div>

          <div className='mx-3'>
            <p className='text-sm font-medium text-chestnut-rose-800'>
              {message}
            </p>
          </div>

          <div className='ml-auto pl-3'>
            <div className='-mx-1.5 -my-1.5'>
              <button
                type='button'
                className='inline-flex rounded-md bg-chestnut-rose-50 p-1.5 text-chestnut-rose-500 hover:bg-chestnut-rose-100 focus:outline-none focus:ring-2 focus:ring-chestnut-rose-600 focus:ring-offset-2 focus:ring-offset-chestnut-rose-50'
              >
                <XMarkIcon className='h-5 w-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
};

export default ToasterMessage;
