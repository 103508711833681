import { useEffect, useState } from 'react';
import { pasaran_list } from '../../../constants/pasaran/';
import { PrediksiContext } from '../../../context/prediksi';
import { BetListType } from '../../../types/bet-list';
import { Result } from '../../../types/result';
import BetTable from './bet-table/bet-table';

export const get_bet_list = async ({
  signal,
  pasaran,
}: {
  signal: AbortSignal;
  pasaran: string;
}) => {
  try {
    const response = await fetch(`/api/v1/bet?pasaran=${pasaran}&bet_type=2d`, {
      signal,
    });

    const res: { message: string; data: { bets: BetListType[] } } =
      await response.json();

    if (!response.ok) throw new Error(res.message);
    return res.data.bets;
  } catch (error: any) {
    if (error.name !== 'AbortError') alert(error.message);
    return [];
  }
};

export const get_results = async ({
  signal,
  pasaran,
}: {
  signal: AbortSignal;
  pasaran: string;
}) => {
  try {
    const response = await fetch(`/api/v1/result/get?pasaran=${pasaran}`, {
      signal,
    });

    const res: { message: string; data: { results: Result[] } } =
      await response.json();

    if (!response.ok) throw new Error(res.message);
    return res.data.results;
  } catch (error: any) {
    if (error.name !== 'AbortError') alert(error.message);
    return [];
  }
};

const Prediksi = () => {
  const [pasaran, set_pasaran] = useState(pasaran_list[0]);
  const [bet_list, set_bet_list] = useState<BetListType[]>([]);
  const [results, set_results] = useState<Result[]>([]);
  const [show_twin, set_show_twin] = useState(true);
  const [shown_numbers, set_shown_numbers] = useState<number[]>([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  ]);
  const [as, set_as] = useState('');
  const [kop, set_kop] = useState('');

  useEffect(() => {
    const abort_controller = new AbortController();

    (async () => {
      const bet_list = await get_bet_list({
        signal: abort_controller.signal,
        pasaran,
      });

      set_bet_list(bet_list);
    })();

    (async () => {
      const results = await get_results({
        pasaran,
        signal: abort_controller.signal,
      });

      set_results(results);
    })();

    return () => {
      abort_controller.abort();
    };
  }, [pasaran]);

  return (
    <>
      <div className='mx-auto max-w-8xl px-8 py-4'>
        <PrediksiContext.Provider
          value={{
            pasaran,
            set_pasaran,
            bet_list,
            set_bet_list,
            get_bet_list,
            results,
            set_results,
            show_twin,
            set_show_twin,
            shown_numbers,
            set_shown_numbers,
            as,
            set_as,
            kop,
            set_kop,
          }}
        >
          <BetTable />
        </PrediksiContext.Provider>
      </div>
    </>
  );
};

export default Prediksi;
