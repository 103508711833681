const pasaran_hasil_hoki = [
  'Bangkok',
  'Brazil',
  'Brunei',
  'Busan',
  'Denmark',
  'France',
  'Germany',
  'Hungary',
  'India',
  'Mexico',
  'Malaysia',
  'Mongolia',
  'Myanmar',
  'Osaka',
  'Philippines',
  'Poland',
  'Seoul',
  'Sweden',
  'TimorLeste',
  'Vietnam',
];

export { pasaran_hasil_hoki };
