import { useEffect } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const Root = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const abort_controller = new AbortController();

    const get_current_agent = async () => {
      try {
        const response = await fetch('/api/v1/agent/current', {
          signal: abort_controller.signal,
        });

        const res = await response.json();
        if (!res.data) throw new Error(res.message);

        if (res.data.exp && res.data.exp * 1000 < Date.now())
          throw new Error('Session expired');

        navigate('/dashboard/prediksi');
      } catch (error: any) {
        if (error.name === 'AbortError') return;
        alert(error.message);
        navigate('/login');
      }
    };

    get_current_agent();

    return () => {
      abort_controller.abort();
    };
  }, [navigate]);

  return (
    <>
      <div className='w-screen h-screen flex justify-center items-center pr-16 pb-20'>
        <MutatingDots
          height='100'
          width='100'
          radius='12.5'
          color='#de5562'
          secondaryColor='#eb818a'
          ariaLabel='mutating-dots-loading'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
        />
      </div>
    </>
  );
};

export default Root;
